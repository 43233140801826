import axios from "../axios/axios";
import { ISignatureSubmitter } from "../type/session";

const getUpComingSessions = async (id: string) => {
  try {
    const { data } = await axios.get(
      `/session/comingSessions?providerId=${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const createSession = async (
  id: string,
  payload: { scheduledSessionType: string }
) => {
  try {
    const { data } = await axios.post(
      `/session/createSession?serviceScheduleId=${id}`,
      payload
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getSessionById = async (id: string) => {
  try {
    const { data } = await axios.get(`/session/session/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getCompleteSession = async (id: string) => {
  try {
    const { data } = await axios.get(
      `/session/completeSessions?providerId=${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const updateSession = async (
  id: string,
  payload: {
    status?: string;
    sessionEndTime?: string;
    approvalBeginTime?: string;
    approvalEndTime?: string;
  }
) => {
  try {
    const { data } = await axios.patch(`/session/updateSession/${id}`, 
      payload,
    );
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

const sendSessionForm = async (payload: {
  sessionId: string;
  formId: string;
  entityType: string;
  entityId: string;
  authId: string;
  authCode: string;
  formData?: any;
  isComplete: boolean;
}) => {
  try {
    const { data } = await axios.post("/session/SendSessionForm", payload);
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

const updateSessionFormRequest = async (
  id: string,
  payload: {
    formData: any;
    reviewData?: any;
    submitters?:ISignatureSubmitter[]
  }
) => {
  try {
    const { data } = await axios.put(
      `/formrequest/${id}/updateSessionFormRequest`,
      payload
    );
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

const UpdateReviewComment = async (
  id: string,
  payload: { comment: string }
) => {
  try {
    const { data } = await axios.patch(
      `/formrequest/${id}/updateComment`,
      payload
    );
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

const getFormRequestById = async (id: string) => {
  try {
    const { data } = await axios.get(`/formrequest/${id}`);
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

//session settings
const getSessionSettings = async () => {
  try {
    const { data } = await axios.get("/sessionSetting");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//session settings
const getFormById = async (id: string) => {
  try {
    const { data } = await axios.get(`/form/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// send signature invitation
const sendSignatureInvitation = async (
  formId: string,
  submitter: ISignatureSubmitter
) => {
  try {
    const { data } = await axios.patch(
      `session/signatureRequest/${formId}`,
      {submitter}
    );
    return data
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const sessionService = {
  getUpComingSessions,
  createSession,
  getSessionById,
  getCompleteSession,
  updateSession,
  sendSessionForm,
  updateSessionFormRequest,
  UpdateReviewComment,
  getFormRequestById,
  getSessionSettings,
  getFormById,
  sendSignatureInvitation
};
