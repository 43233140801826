import moment from "moment";
import { IAvailabilityDetails } from "../type/availability";
import {
  IContactDetails,
  IEmployementDetails,
  IFiles,
  IFormData,
  IForms,
  IFormsAllData,
  IBookingDetails,
//   IProviderOnboardingForm,
  IProviderUtilization,
  IEmployeePortalData,
} from "../type/providerDetails";

export const EmployementDetails :IEmployementDetails = {
  id: "",
  frontegg_tenantId: "",
  descope_tenantId: "",
  firstName: "",
  lastName: "",
  businessUnit: "",
  employmentStatus: "",
  languages: [],
  type: "",
  specialities: [],
  providerType: "",
  onBoardingStage: "",
  checklist: [],
  files: {},
  providerProfile: {
    containerName: "",
    fileId: "",
    fileName: "",
    key: "",
    url: ""
  },
  isSupervisor: false,
  createdAt: "",
  updatedAt: "",
  messageCount: 0,
  supervisor: null,
  supervisingDetail: null
}

export const ContactDetails : IContactDetails = {
    entityType: "",
  entityId: "",
  phoneNumber :"",
  email: "",
  address: {
      fullAddress: "",
      lng: 0,
      lat: 0
  },
  schoolAddress:{
    fullAddress: "",
    lng: 0,
    lat: 0
  },
  schoolCity: "",
  schoolState: "",
  schoolZipCode: "",
  city: "",
  state: "",
  zipCode:"" ,
  contactMethod:"" ,
  bestTimeToContact:"" ,
  id: "",
  createdAt: "",
  updatedAt: "",
  otherAddresses: [],
}

// export const AvailabilityDetails:IAvailabilityDetails ={
//     entityType:"",
//     entityId: "",
//     beginDate: moment().local().format("YYYY-MM-DD"),
//     endDate: moment().local().add(6, "M").local().format("YYYY-MM-DD"),
//     selectedDays: [
//       {
//         dayOfWeek: "",
//         startTime: "",
//         endTime: "",
//       },
//     ],
//     createdAt: "",
//     updatedAt: "",
//     heatMap: [],
//     timeSlots:[],
//     availabilitySelectedDays:[],
//     availabilityName: "", //added by Hui
//     id:"",
// }

// initial state for Availability
export const defaultProviderAvailability: IAvailabilityDetails = {
  id: "",
  entityType: "Provider",
  entityId: null,
  beginDate: moment().local().format("YYYY-MM-DD"),
  endDate: moment().local().add(6, "M").local().format("YYYY-MM-DD"),
  createdAt: "",
  updatedAt: "",
  selectedDays: [
    {
      dayOfWeek: "",
      startTime: "",
      endTime: "",
    },
  ],
  heatMap: [],
  timeSlots: [],
  availabilitySelectedDays: [],
  availabilityName: "",
};

export const ProviderUtilization:IProviderUtilization = {
    timeTables: [],
    remainingHours: 0,
    remainingAuthHours: 0,
    totalAvailability: 0,
    totalAuthorization: 0,
    totalBooked: 0,
    weeksHaveBooked: 0,
    totalWeeksChecked: 0,
    weeklyAvgBooked: 0,
    weeklyAvgAvailability: 0,
    weeklyAvgAuthorization: 0,
    weeklyAvgRemainedByAvailability: 0,
    weeklyAvgRemainedByAuthorization: 0,
    utilizationPcntByAvailability: 0,
    utilizationPcntByAuthorization: undefined,
    error: "",
    request: 
    {
        requestType: "",
        tenantId: "",
        entityType: "",
        entityId: ""
    }
    
}

export const defaultFile :IFiles ={
    id: "",
    fileName: "",
    fileKey: "",
    containerName: "",
    fileUrl: "",
    category: "",
    type: "",
    entityId: "",
    uploadedAt: "",
    updatedAt: "",
    uploadedBy: ""
}

//interface for booking details
export const bookingData: IBookingDetails = {
  bookedProviderId: {
    id: "",
    frontegg_tenantId: "",
    descope_tenantId: "",
    firstName: "",
    lastName: "",
    businessUnit: "",
    employmentStatus: "",
    languages: [],
    type: "",
    specialities: [],
    providerType: "",
    onBoardingStage: "",
    checklist: [],
    files: {},
    providerProfile: {
      containerName: "",
      fileId: "",
      fileName: "",
      key: "",
      url: "",
    },
    isSupervisor: false,
    createdAt: "",
    updatedAt: "",
    messageCount: 0,
    supervisor: null,
    supervisingDetail: null
  },
  bookedClientId: {
    id: "",
    frontegg_tenantId: "",
    descope_tenantId: "",
    parentFirstName: "",
    parentLastName: "",
    childFirstName: "",
    childLastName: "",
    childDateOfBirth: null,
    childGender: "",
    childLanguages: [],
    businessUnit: [],
    clientType: "",
    onTrackStage: "",
    concernedStage: [],
    intakeStage: "",
    concerns: "",
    hearAboutUs: "",
    clientProfile: {
      containerName: "",
      fileId: "",
      fileName: "",
      key: "",
      url: "",
    },
    stage: "",
    createdAt: null,
    updatedAt: null,
    messageCount: 0
  },
  status: "",
  id: "",
  specificDate: "",
  fromTime: "",
  toTime: "",
  repeatWorkDays: "",
  repeatEndsOn: "",
  repeatService: 0,
  serviceType: "",
  serviceLocation: "",
  description: "",
  authId: "",
  authCode: "",
  whoCancelled: "",
  cancellationReason: "",
  createdBy: "",
  createdAt: "",
  updatedAt: "",
  sessionDetail: null,
  sessionStatus: ""
};

export const form: IForms = {
    formType: "",
    url: null,
    formCategory: "",
    createdAt: "",
    updatedAt: "",
  };
  export const formData: IFormData = {
    status: "",
    form: form,
    id: "",
  };
  export const AllformData: IFormsAllData = {
    employeePacketForm: [formData]
  };

// export const ProviderOnboardingForm: IProviderOnboardingForm = {
//   form: AllformData,
//   frontegg_tenantId: "",
//   id: "",
//   orgLogo: {
//     containerName: "",
//     fileName: "",
//     key: "",
//     url: "",
//   },
//   orgName: "",
//   orgUrl: "",
//   updatedAt: "",
// };


export const DefaultProviderDetails: IEmployeePortalData = {
  contactDetails: ContactDetails,
  availabilityDetails: [defaultProviderAvailability],
  providerUtilization: ProviderUtilization,
  providerInsurance: [],
  providerFiles: [defaultFile],
  employmentDetails: EmployementDetails,
  providerForms: AllformData,
  bookingData: [bookingData]
};

