import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  GetFormByID,
  GetSessionById,
  SendSessionForm,
  setCurrentSessionForm,
} from "../../redux/features/session/sessionSlice";
import {
  CalculateDuration,
  DefaultSessionForm,
  formatTimeTo24Hour,
} from "../../constants/session";
import { IEmployeePortalData } from "../../type/providerDetails";

const SessionSignout: React.FC = () => {
  //#region variable region
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [previousRoute, setPreviousRoute] = useState<string>();
  const [showCompleteMessage, setShowCompleteMessage] =
    useState<boolean>(false);
  const providerDetails = useAppSelector<IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );
  const { currentSession, currentSessionForm } = useAppSelector(
    (state) => state.sessionSlice
  );

  //#endregion

  //#region survey region
  const [survey, setSurvey] = useState(
    new Model(currentSessionForm?.formSchema)
  );
  survey.showCompletedPage = false;
  survey.onAfterRenderQuestion.add(function (survey, options) {
    if (options.question.name === "question3") {
      const inputElement = options.htmlElement.querySelector("input");
      if (inputElement) {
        inputElement.disabled = true;
      }
    }
  });

  survey.onComplete.add((sender, options) => {
    if (currentSession && currentSessionForm) {
      const isSignProcedureForm =
        currentSessionForm.name === DefaultSessionForm.SIGN_IN_NOTE ||
        currentSessionForm.name === DefaultSessionForm.SIGN_OUT_NOTE;
      dispatch(
        SendSessionForm({
          authCode: currentSession?.serviceSchedule.authCode,
          authId: currentSession.serviceSchedule.authId,
          entityId: isSignProcedureForm
            ? currentSession.serviceSchedule.bookedClientId.id
            : providerDetails.employmentDetails.id,
          entityType: isSignProcedureForm ? "Client" : "Provider",
          formId: currentSessionForm?.id,
          formData: survey.data,
          sessionId: currentSession.id,
          isComplete: true,
          formName: currentSessionForm.name,
        })
      );

      dispatch(setCurrentSessionForm(null));
      setShowCompleteMessage(true);
    }
  });

  //#endregion

  //#region methods region
  const formatLocalDateTime = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  //pre - populate fields
  const handleAutoPopulateForm = () => {
    if (!currentSessionForm) {
      return;
    }
    const surveyData = new Model(currentSessionForm?.formSchema);
    if (currentSession) {
      switch (currentSessionForm.formSchema.title) {
        case DefaultSessionForm.SIGN_IN_NOTE:
        case DefaultSessionForm.SIGN_OUT_NOTE:
          surveyData.data = {
            question1:
              currentSession.serviceSchedule?.bookedClientId?.childFirstName ||
              "",
            question2:
              currentSession.serviceSchedule?.bookedClientId?.childLastName ||
              "",
            question3:
              currentSessionForm.name === DefaultSessionForm.SIGN_IN_NOTE
                ? formatLocalDateTime(
                    new Date(currentSession.serviceSchedule?.createdAt)
                  )
                : formatLocalDateTime(new Date()),
          };
          break;

        case DefaultSessionForm.SESSION_NOTE:
          surveyData.data = {
            clientName: `${
              currentSession.serviceSchedule?.bookedClientId?.childFirstName ||
              ""
            } ${
              currentSession.serviceSchedule?.bookedClientId?.childLastName ||
              ""
            }`,
            childDateOfBirth:
              currentSession.serviceSchedule?.bookedClientId
                ?.childDateOfBirth || "",
            childGender:
              currentSession.serviceSchedule?.bookedClientId?.childGender || "",
            appointmentDate: currentSession.serviceSchedule?.specificDate || "",
            appointmentStartTime: currentSession.approvalBeginTime
              ? formatTimeTo24Hour(currentSession.approvalBeginTime)
              : "",
            appointmentEndTime: currentSession.approvalEndTime
              ? formatTimeTo24Hour(currentSession.approvalEndTime)
              : "",
            sessionDuration: currentSession.sessionEndDtm
              ? CalculateDuration(
                  currentSession?.sessionBeginDtm,
                  currentSession?.sessionEndDtm
                )
              : "",
            serviceLocationAddreess:
              currentSession.serviceSchedule?.serviceLocation || "",
          };
          break;
        default:
          console.log("Unhandled form schema title.");
      }
    }
    surveyData.rendered = true;
    setSurvey(surveyData);
  };

  //#endregion

  //#region useEffect region

  useEffect(() => {
    handleAutoPopulateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSessionForm?.id, currentSession]);

  useEffect(() => {
    if (id && currentSessionForm === null) {
      dispatch(GetFormByID({ id }));
    }
    const path = localStorage.getItem("previousPath");
    const currentSessionId = localStorage.getItem("currentSessionId");
    if (path) {
      setPreviousRoute(path);
    }
    if (currentSessionId) {
      dispatch(GetSessionById({ id: currentSessionId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  //#endregion

  return (
    <div className="patientSignOut">
      <Box className="signOutHeader">
        <Box className="flex">
          <Box
            className="back-btn"
            onClick={() => {
              if (previousRoute) {
                navigate(previousRoute);
              } else {
                navigate(-1);
              }
            }}
          >
            <KeyboardBackspaceIcon color="primary" />
            <Typography>Back</Typography>
          </Box>
          <Typography className="title">
            {currentSessionForm?.formSchema?.title}
          </Typography>
        </Box>
      </Box>
      <Box>
        {showCompleteMessage ? (
          <Typography className="signOutComplete">
            Thank you for completing the form.
          </Typography>
        ) : (
          <Box bgcolor={"#f3f3f3"} className="surveyFormBlock">
            <Survey model={survey} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SessionSignout;
