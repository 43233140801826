import { IAvailabilityDetails } from "./availability";
import { IClientBasicDetails } from "./client-info";
import { ISession } from "./session";

export interface IEmployementDetails {
  id: string;
  frontegg_tenantId: string;
  descope_tenantId: string;
  firstName: string;
  lastName: string;
  businessUnit: string;
  employmentStatus: string;
  languages: [];
  type: string;
  specialities: [];
  providerType: string;
  onBoardingStage: string;
  checklist: [];
  files: {};
  providerProfile: {
    containerName: string;
    fileId: string;
    fileName: string;
    key: string;
    url: string;
  };
  supervisor:ISupervisorDetails|null;
  supervisingDetail:ISupervisorDetails|null;
  isSupervisor: boolean;
  createdAt: string;
  updatedAt: string;
  messageCount: number;
}

export interface ISupervisorDetails {
  id:string;
  team:string[];
  client:string[];
  createdAt:string;
  updatedAt:string;
}

//provider availability interface
export interface IContactDetails {
  entityType: string;
  entityId: string | null;
  phoneNumber: string;
  email: string;
  address: AddressObject;
  schoolAddress: AddressObject;
  schoolCity: string;
  schoolState: string;
  schoolZipCode: string;
  city: string;
  state: string;
  zipCode: string;
  contactMethod: string;
  bestTimeToContact: string;
  id: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  otherAddresses: OtherAddresses[];
}

interface AddressObject {
  fullAddress: string;
  lng: number;
  lat: number;
}

export interface OtherAddresses {
  address: AddressObject;
  state: string;
  city: string;
  zipCode: string;
}

//provider utilization
export interface IProviderUtilization {
  request: Request;
  timeTables: TimeTable[];
  remainingHours: number;
  remainingAuthHours: number;
  totalAvailability: number;
  totalAuthorization: number;
  totalBooked: number;
  weeksHaveBooked: number;
  totalWeeksChecked: number;
  weeklyAvgBooked: number;
  weeklyAvgAvailability: number;
  weeklyAvgAuthorization: number;
  weeklyAvgRemainedByAvailability: number;
  weeklyAvgRemainedByAuthorization: number;
  utilizationPcntByAvailability: number;
  utilizationPcntByAuthorization: any;
  error: string;
}

export interface TimeTable {
  timeTableType: string;
  timeTableId: string;
  timeTableCode: string;
  remainingHours: number;
  totalAvailability: number;
  totalBooked: number;
  weeksHaveBooked: number;
  totalWeeksChecked: number;
  weeklyAvgBooked: number;
  weeklyAvgAvailability: number;
  weeklyAvgRemainedByAvailability: number;
  utilizationPcntByAvailability: number;
  remainingAuthHours: number;
  totalAuthorization: number;
  weeklyAvgAuthorization: number;
  weeklyAvgRemainedByAuthorization: number;
  utilizationPcntByAuthorization: number;
  error: string;
}

export interface Request {
  requestType: string;
  tenantId: string;
  entityType: string;
  entityId: string;
}

//Same as backend file category
export enum FileCategory {
  Intake="Intake",
  Assessment="Assessment", //for assessment form (pdf)
  Onboarding="Onboarding",
  Authorization="Authorization",
  Credentialing="Credentialing",
  Session="Session",
  Evaluation="Evaluation", //for assessment evaluation (png & pdf)
}

//provider files interface
export interface IFiles {
  id: string;
  fileName: string;
  fileKey: string;
  containerName: string;
  fileUrl: string;
  category: string;
  type: string;
  entityId: string;
  uploadedAt: string;
  updatedAt: string;
  uploadedBy: string;
}

//provider booking details interface
export interface IBookingDetails {
  bookedProviderId: IEmployementDetails;
  bookedClientId: IClientBasicDetails;
  status: string;
  specificDate: string;
  fromTime: string;
  toTime: string;
  repeatWorkDays: string;
  repeatEndsOn: string;
  repeatService: Number;
  serviceType: string;
  serviceLocation: string;
  description: string;
  id: string;
  authId: string;
  authCode: string;
  whoCancelled: string;
  cancellationReason: string;
  sessionStatus: string;
  sessionDetail: ISession | null;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

// export interface IProviderOnboardingForm {
//   form: IFormsAllData;
//   frontegg_tenantId: string;
//   id: string;
//   orgLogo: {
//     containerName: string;
//     fileName: string;
//     key: string;
//     url: string;
//   };
//   orgName: string;
//   orgUrl: string;
//   updatedAt: string;
// }

export type IFormsAllData = Record<"employeePacketForm", IFormData[]>;

export interface IFormData {
  status: string;
  form: IForms;
  id: string;
}

export type Form = "createdAt" | "formCategory" | "formType" | "updatedAt";

export type IForm = Record<Form, string>;
export interface IForms extends IForm {
  url: null;
}

//ProviderListSlice interface
export interface ListData {
  singleProvider: IEmployeePortalData;
  listDataChange: boolean;
}

//provider interface
export interface IEmployeePortalData {
  contactDetails: IContactDetails;
  availabilityDetails: IAvailabilityDetails[];
  providerUtilization: IProviderUtilization;
  providerInsurance: any[];
  providerFiles: IFiles[];
  // providerBookingDetails:IEmployementDetails[]
  employmentDetails: IEmployementDetails;
  bookingData: IBookingDetails[];
  providerForms: IFormsAllData;
}

// //ProviderSlice interface
// export interface ProviderForms {
//   employmentDetails: IEmployementDetails;
//   contactDetails: IContactDetails;
//   availabilityDetails: IAvailabilityDetails[];
//   providerInsurance: any[];
//   // providerFiles: any[];
//   providerUtilization: IProviderUtilization;
// }

export interface stages {
  id: string;
  //name: string;  remove this, use stageName instead!!
  goal: string;
  provider: IEmployementDetails[];
  checkList: string[];
  index: number;
  stageName: string;
  boardColumnNames: any[];
}

export interface Board {
  id: string;
  name: string;
  description: string;
  boardType: string;
  stages: stages[];
  boardName: string;
  primitive?: boolean;
  index: number; //board order index
  permission: {
    viewProvider: string[];
  };
}
