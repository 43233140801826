export enum SessionType {
  OneOnOne = "1-on-1",
  GroupSession = "Group Session",
  EmployeeSession = "Employee Session",
}

//must same as in the backend
export enum SessionStatus {
  NOT_STARTED = "Not Started",
  STARTED = "Started",
  CLOSED = "Closed",
  INREVIEW = "In Review",
  SIGNATURE = "Request Signature",
  FINALIZED = "Finalized",
}

export enum DefaultSessionForm {
  SIGN_IN_NOTE = "Sign In Note",
  SIGN_OUT_NOTE = "Sign Out Note",
  SESSION_NOTE = "Session Note"
}

export enum FormRequestStatus {
  Sent = "Sent",
  Started = "Started",
  Saved = "Saved",
  Reminded = "Reminded", //if reminder step is added: reminded
  Submitted = "Submitted",
  Evaluated = "Evaluated", //if evaluation step is added: evaluated
  Cancelled = "Cancelled", //for admin only, to mark it as cancell
  Mapped = "Mapped", //if mapping step is added; mapped
  Error = "Error", // exception default status
  ReviewComplete = "Review Complete",
  InReview = "In Review",
  SignatureRequested = "Signature Requested", //signature requested
  SignatureReceived = "Signature Received", //signature received
  Completed="Completed"
}
// export const SessionDetails :ISession = {
//   id: "",
//   descope_tenantId: null,
//   clientIds: [],
//   providerId: "",
//   serviceScheduleId: "",
//   serviceType: null,
//   serviceLocation: null,
//   sessionDateUTC: null,
//   sessionBeginTime: null,
//   sessionEndTime: null,
//   requiredForms: [],
//   formRequests: [],
//   dataRequests: [],
//   createdAt: "",
//   updatedAt: "",
//   sessionType: null,
//   status: null,
//   scheduleData: {
//     specificDate: "",
//     fromTime: "",
//     toTime: ""
//   },
//   participants: [],
// };
export const formatTimeToAMPM = (time: string): string => {
  // Split the time into hours and minutes
  const [hoursStr, minutesStr] = time.split(":");
  let hours = parseInt(hoursStr);
  let minutes = parseInt(minutesStr);

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, set to 12

  // Ensure hours and minutes are two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Return formatted time with AM/PM
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatTimeTo24Hour = (time: string): string => {
  // Extract the time parts (hours, minutes) and AM/PM
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  // Convert hours to 24-hour format
  if (modifier === 'PM' && hours < 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  // Format hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const CalculateDuration = (
  startTime: string,
  endTime: string
): string => {
  // Parse the start and end times as Date objects
  const start = new Date(startTime);
  const end = new Date(endTime);

  // Ensure the inputs are valid dates
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return "Invalid start or end time.";
  }

  // Calculate the duration in milliseconds
  const durationMs = end.getTime() - start.getTime();

  // If the duration is negative, return an error message
  if (durationMs < 0) {
    return "End time must be after start time.";
  }

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(durationMs / (1000 * 60 * 60));
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);

  // Format the result as a human-readable string
  return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
};