import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import "./sessionFormReview.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Model } from "survey-core";
import { IReviewForm, ISignatureSubmitter } from "../../type/session";
import { Survey } from "survey-react-ui";
import {
  GetFormRequestByID,
  GetSessionById,
  SendSignatureInvitation,
  setCurrentSessionForm,
  UpdateReviewComment,
  UpdatesSessionFormRequest,
} from "../../redux/features/session/sessionSlice";
import moment from "moment";
import { IEmployeePortalData } from "../../type/providerDetails";
import { FormRequestStatus } from "../../constants/session";
import { Close } from "@mui/icons-material";

export enum SignatureStatus {
  SignatureRequested = "Requested",
  SignatureReceived = "Signed",
  Invite = "Invite to Sign",
}

const SessionFormReview = () => {
  //#region variable region
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [reviewData, setReviewData] = useState<IReviewForm | null>(null);
  const [reviewComment, setReviewComment] = useState<string>("");
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [previousRoute, setPreviousRoute] = useState<string>("");
  const [signatureRequired, setSignatureRequired] = useState<{
    parent: boolean;
    tech: boolean;
    supervisor: boolean;
  }>({
    parent: false,
    tech: false,
    supervisor: false,
  });
  const [isSessionSupervisor, setIsSessionSupervisor] =
    useState<boolean>(false);
  const [signatureStatus, setSignatureStatus] = useState<{
    parent: string | null;
    tech: string | null;
    supervisor: string | null;
  }>({
    parent: null,
    tech: null,
    supervisor: null,
  });
  const currentSession = useAppSelector(
    (state) => state.sessionSlice.currentSession
  );
  const currentformRequest = useAppSelector(
    (state) => state.sessionSlice.formRequest
  );
  const providerDetails = useAppSelector<IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );
  const [survey, setSurvey] = useState<Model>(
    new Model(currentformRequest?.form.formSchema)
  );
  //#endregion

  //#region methods region
  const handleUpdateFormRequest = () => {
    if (!survey || !currentformRequest || !currentSession) {
      return;
    }
    if (currentformRequest.status === FormRequestStatus.SignatureRequested) {
      let updatedSubmitter = currentformRequest.submitters;
      updatedSubmitter = updatedSubmitter.map((item) => {
        if (
          currentSession.supervisorId &&
          item.id === currentSession.supervisorId &&
          item.role === "Supervisor"
        ) {
          return {
            id: providerDetails.employmentDetails.id,
            role: "Supervisor",
            isSubmitted: true,
          };
        }
        if (
          item.id === providerDetails.employmentDetails.id &&
          item.role === "Tech"
        ) {
          return {
            id: providerDetails.employmentDetails.id,
            role: "Tech",
            isSubmitted: true,
          };
        }
        return item;
      });
      dispatch(
        UpdatesSessionFormRequest({
          formId: currentformRequest?.id,
          formData: survey.data,
          submitters: updatedSubmitter,
        })
      );
    } else {
      dispatch(
        UpdatesSessionFormRequest({
          formId: currentformRequest?.id,
          formData: survey.data,
          reviewData: reviewData,
        })
      );
    }
  };

  const handleReviewChecklist = (item: string) => {
    if (reviewData) {
      const isExist = reviewData?.checkList?.some((ele) => ele === item);
      if (isExist) {
        const updatesQuestionReviews = reviewData?.checkList?.filter(
          (ele) => item !== ele
        );
        setReviewData({
          ...reviewData,
          checkList: updatesQuestionReviews || [],
        });
      } else {
        setReviewData({
          ...reviewData,
          checkList: [...(reviewData?.checkList || []), item],
        });
      }
    } else {
      setReviewData({
        reviewComments: [],
        checkList: [item],
      });
    }
  };

  const handleAddreviewComment = () => {
    if (currentformRequest)
      dispatch(
        UpdateReviewComment({
          formId: currentformRequest.id,
          comment: reviewComment,
        })
      );
    setIsUpdated(true);
    setReviewComment("");
  };
  const hasChangesSaved = () => {
    if (!reviewData?.checkList || !currentformRequest) return true;
    const isChecklistChanged =
      reviewData.checkList.length ===
      currentformRequest.reviewData?.checkList?.length;
    return isChecklistChanged;
  };

  const confirmDiscardChanges = () => {
    setOpen(false);
    navigate(previousRoute);
  };
  const formatData = () => {
    if (!currentformRequest) {
      return;
    }
    let questions: any[] = [];
    currentformRequest?.form.formSchema.pages.forEach((item) => {
      questions = [...questions, ...item.elements];
    });
    if (currentformRequest?.formData) {
      let SurveyData = new Model(currentformRequest.form.formSchema);
      SurveyData.data = currentformRequest.formData;
      SurveyData.showQuestionNumbers = "off";
      SurveyData.showNavigationButtons = {
        complete: false,
      };
      setSurvey(SurveyData);
    }
    if (currentformRequest?.reviewData) {
      setReviewData(currentformRequest.reviewData);
    }
  };

  const handleSignatureInvitation = (entityType: string) => {
    if (!currentSession) {
      return;
    }
    let submitter: ISignatureSubmitter | null = null;
    if (entityType === "Parent") {
      submitter = {
        id: currentSession?.serviceSchedule.bookedClientId.id,
        role: "Parent",
        isSubmitted: false,
      };
    } else if (entityType === "Tech") {
      submitter = {
        id: currentSession?.serviceSchedule.bookedProviderId.id,
        role: "Tech",
        isSubmitted: false,
      };
    } else if (entityType === "Supervisor" && currentSession.supervisorId) {
      submitter = {
        id: currentSession.supervisorId,
        role: "Supervisor",
        isSubmitted: false,
      };
    }
    if (submitter && currentformRequest) {
      dispatch(
        SendSignatureInvitation({
          formId: currentformRequest?.id,
          submitter: submitter,
        })
      );
      IdentifySignatureRequest();
    }
  };

  const IdentifySignatureRequest = () => {
    if (!currentformRequest) {
      return;
    }

    let updatedSignatureRequired = signatureRequired;
    currentformRequest.form.formSchema.pages[0].elements.forEach((element) => {
      if (element.title === "Signatures") {
        element?.elements.forEach((item: any) => {
          if (item.name === "parentSignature") {
            updatedSignatureRequired = {
              ...updatedSignatureRequired,
              parent: true,
            };
          }
          if (item.name === "supervisorSignature") {
            updatedSignatureRequired = {
              ...updatedSignatureRequired,
              supervisor: true,
            };
          }
          if (item.name === "renderingProviderSignature") {
            updatedSignatureRequired = {
              ...updatedSignatureRequired,
              tech: true,
            };
          }
        });
      }
    });
    setSignatureRequired(updatedSignatureRequired);
    if (currentformRequest.submitters?.length > 0) {
      let updatedSubmitter = signatureStatus;
      currentformRequest.submitters.forEach((item) => {
        if (item.role === "Parent") {
          updatedSubmitter = {
            ...updatedSubmitter,
            parent: item.isSubmitted
              ? SignatureStatus.SignatureReceived
              : SignatureStatus.SignatureRequested,
          };
        }
        if (item.role === "Tech") {
          updatedSubmitter = {
            ...updatedSubmitter,
            tech: item.isSubmitted
              ? SignatureStatus.SignatureReceived
              : SignatureStatus.SignatureRequested,
          };
        }
        if (item.role === "Supervisor") {
          updatedSubmitter = {
            ...updatedSubmitter,
            supervisor: item.isSubmitted
              ? SignatureStatus.SignatureReceived
              : SignatureStatus.SignatureRequested,
          };
        }
      });
      setSignatureStatus(updatedSubmitter);
    }
  };
  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (currentSession) {
      if (
        currentSession?.supervisorId === providerDetails.employmentDetails.id
      ) {
        setIsSessionSupervisor(true);
        return;
      }
    }
  }, [currentSession, providerDetails]);

  useEffect(() => {
    if (id) {
      dispatch(GetFormRequestByID({ id }));
    }
    const path = localStorage.getItem("previousPath");
    const currentSessionId = localStorage.getItem("currentSessionId");
    if (path) {
      setPreviousRoute(path);
    }
    if (currentSessionId) {
      dispatch(GetSessionById({ id: currentSessionId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (currentformRequest) {
      formatData();
      if (currentformRequest?.form.name === "Session Note") {
        IdentifySignatureRequest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentformRequest]);

  useEffect(() => {
    if (
      currentformRequest?.reviewData?.reviewComments !== undefined &&
      isUpdated
    ) {
      setReviewData({
        checkList: reviewData?.checkList || [],
        reviewComments: currentformRequest?.reviewData?.reviewComments || [],
      });
      setIsUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentformRequest?.reviewData?.reviewComments]);

  //#endregion
  return (
    <Box className="formReview">
      <Grid className="formReviewHeader" container spacing={1}>
        <Grid item lg={3} md={12}>
          <Box className="firstSection">
            <Box
              className="back-btn"
              onClick={() => {
                if (hasChangesSaved()) {
                  navigate(previousRoute);
                  dispatch(setCurrentSessionForm(null));
                } else {
                  setOpen(true);
                }
              }}
            >
              <KeyboardBackspaceIcon />
              <Typography>Back</Typography>
            </Box>
            <Typography className="formTitle-session">
              Review Data Session
            </Typography>
          </Box>
        </Grid>
        <Grid className="midSection" item lg={6} md={10}>
          <Box className="flex">
            <Typography className="entityName">Client : </Typography>
            <span className="userMapCart__img">
              <img
                src={
                  currentSession?.serviceSchedule.bookedClientId.clientProfile
                    ?.url || ClientImg
                }
                className="userSmallCircle"
                alt={""}
              />
            </span>
            <Typography className="ClientName">
              {currentSession?.serviceSchedule.bookedClientId.childFirstName}{" "}
              {currentSession?.serviceSchedule.bookedClientId.childLastName}
            </Typography>
          </Box>
          <Box className="flex">
            <Typography className="entityName">Provider : </Typography>
            <span className="userMapCart__img">
              <img
                src={
                  currentSession?.serviceSchedule.bookedProviderId
                    .providerProfile?.url || ProviderImg
                }
                className="userSmallCircle"
                alt={""}
              />
            </span>
            <Typography className="ClientName">
              {currentSession?.serviceSchedule.bookedProviderId.firstName}{" "}
              {currentSession?.serviceSchedule.bookedProviderId.lastName}
            </Typography>
          </Box>
        </Grid>
        <Grid className="lastSection" item lg={3} md={2}>
          {isSessionSupervisor &&
            currentformRequest?.status !== FormRequestStatus.Completed && (
              <Button
                variant="contained"
                onClick={() => handleUpdateFormRequest()}
                sx={{ height: "45px" }}
              >
                {signatureStatus.supervisor ===
                SignatureStatus.SignatureRequested
                  ? "Save Signature"
                  : "Save Review"}
              </Button>
            )}
          {!isSessionSupervisor &&
            currentformRequest?.status !== FormRequestStatus.Completed && (
              <Button
                variant="contained"
                onClick={() => handleUpdateFormRequest()}
                sx={{ height: "45px" }}
              >
                {signatureStatus.tech === SignatureStatus.SignatureRequested
                  ? "Save Signature"
                  : "Save Review"}
              </Button>
            )}
        </Grid>
      </Grid>
      <Box className="formReviewDetails">
        <Grid container columnSpacing={3}>
          <Grid item sm={12} md={3} lg={3}>
            <Box className="question-header2">
              <Typography>Checklist</Typography>
            </Box>
            <Box className="questionLists">
              {currentformRequest?.form?.checkList &&
                currentformRequest?.form?.checkList.map(
                  (item: any, index: number) => (
                    <Box key={index} className={"customCheckBox"}>
                      <input
                        checked={reviewData?.checkList?.some(
                          (ele) => ele === item
                        )}
                        type="checkbox"
                        onClick={() => {
                          handleReviewChecklist(item);
                        }}
                      />
                      <label>{item}</label>
                    </Box>
                  )
                )}

              {currentformRequest?.form.name === "Session Note" &&
                !isSessionSupervisor &&
                (currentformRequest?.status ===
                  FormRequestStatus.ReviewComplete ||
                  currentformRequest?.status ===
                    FormRequestStatus.SignatureRequested || currentformRequest.status === FormRequestStatus.Completed) && (
                  <>
                    <Box className="question-header2">
                      <Typography>Actions</Typography>
                    </Box>
                    {signatureRequired.tech && (
                      <Box className="signature-invitation">
                        <label>Provider Signature</label>
                        <button
                          className={
                            signatureStatus.tech ===
                            SignatureStatus.SignatureRequested
                              ? "request"
                              : signatureStatus.tech ===
                                SignatureStatus.SignatureReceived
                              ? "signed"
                              : "invite"
                          }
                          onClick={() => {
                            if (
                              signatureStatus.tech !==
                              SignatureStatus.SignatureRequested
                            ) {
                              handleSignatureInvitation("Tech");
                            }
                          }}
                        >
                          {signatureStatus.tech || SignatureStatus.Invite}
                        </button>
                      </Box>
                    )}
                    {currentSession?.supervisorId &&
                      signatureRequired.supervisor && (
                        <Box className="signature-invitation">
                          <label>Supervisor Signature</label>

                          <button
                            className={
                              signatureStatus.supervisor ===
                              SignatureStatus.SignatureRequested
                                ? "request"
                                : signatureStatus.supervisor ===
                                  SignatureStatus.SignatureReceived
                                ? "signed"
                                : "invite"
                            }
                            onClick={() => {
                              if (
                                signatureStatus.supervisor !==
                                SignatureStatus.SignatureRequested
                              ) {
                                handleSignatureInvitation("Supervisor");
                              }
                            }}
                          >
                            {signatureStatus.supervisor ||
                              SignatureStatus.Invite}
                          </button>
                        </Box>
                      )}
                    {signatureRequired.parent && (
                      <Box className="signature-invitation">
                        <label>Parent Signature</label>
                        <button
                          className={
                            signatureStatus.parent ===
                            SignatureStatus.SignatureRequested
                              ? "request"
                              : signatureStatus.parent ===
                                SignatureStatus.SignatureReceived
                              ? "signed"
                              : "invite"
                          }
                          onClick={() => {
                            if (
                              signatureStatus.parent !==
                              SignatureStatus.SignatureRequested
                            ) {
                              handleSignatureInvitation("Parent");
                            }
                          }}
                        >
                          {signatureStatus.parent || SignatureStatus.Invite}
                        </button>
                      </Box>
                    )}
                  </>
                )}
            </Box>
          </Grid>
          <Grid item sm={12} md={5} lg={6} className="formDetails">
            <Box className="reviewQuestionset">
              {survey && (
                <Box bgcolor={"#f3f3f3"} className="review-survey">
                  <Survey model={survey} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Typography className="formName">Review Comments</Typography>
            <Box className="reviewComments">
              {currentformRequest?.reviewData?.reviewComments &&
                currentformRequest?.reviewData?.reviewComments.map(
                  (item, index) => (
                    <Box className="comment" key={index}>
                      <img
                        src={item.fromUser?.profilePictureUrl || ClientImg}
                        className="userSmallCircle"
                        alt={""}
                      />
                      <Box className="userInfo">
                        <Box className="userInfo__head">
                          <Typography className="ClientName">
                            {item.fromUser.firstName} {item.fromUser.lastName}
                          </Typography>
                          <Typography className="commentTime">
                            {moment(item.createdAt).calendar()}
                          </Typography>
                        </Box>
                        <Typography className="comment-text">
                          {item.comment}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
            </Box>

            <Box className="CommentEntryBox">
              <TextField
                type="text"
                placeholder="Leave an internal message..."
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddreviewComment();
                  }
                }}
              />

              <Button variant="contained" onClick={handleAddreviewComment}>
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={open} className="checklistModal">
        <div>
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <p className="smallModel__title">Unsaved Changes</p>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <DialogContent>
            <Typography
              style={{ fontSize: "18px", padding: "15px", textAlign: "center" }}
            >
              You have unsaved changes. Are you sure you want to leave without
              saving?
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              boxShadow: "inset 0px 1px 0px #f0f0f0",
              justifyContent: "space-between",
            }}
          >
            <Button variant="outlined" onClick={() => confirmDiscardChanges()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                handleUpdateFormRequest();
                navigate(previousRoute);
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default SessionFormReview;
